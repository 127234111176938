import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import BackgroundImage from '../BackgroundImage/BackgroundImage'
import Vimeo from '@u-wave/react-vimeo'
import debounce from 'lodash/debounce'

const Styled = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  pointer-events: none;
  overflow: hidden;

  iframe {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    border: 0;
    outline: 0;
    transform: translate(-50%, -50%);
  }
`

const Placeholder = styled(BackgroundImage)``

const BackgroundVideo = props => {
  const { className, videoID, autoplay, fluid } = props

  const targetRef = useRef(null);
  const sourceRef = useRef(null);

  const resize = () => {
    if (!targetRef || !sourceRef) return;

    const target = targetRef.current;
    const source = sourceRef.current.player.element;

    source.style.width = null;
    source.style.height = null;

    const targetRect = target.getBoundingClientRect();
    const ratio = source.width / source.height;

    let width = source.width;
    let height = source.height;

    if (height < targetRect.height) {
      height = targetRect.height;
      width = height * ratio;
    }

    if (width < targetRect.width) {
      width = targetRect.width;
      height = width / ratio;
    }

    source.style.width = `${Math.ceil(width)}px`;
    source.style.height = `${Math.ceil(height)}px`;
  }

  const handleResize = debounce(resize, 36);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <Styled ref={targetRef} {...{ className }}>
      <Placeholder {...{ fluid }} />
      <Vimeo
        ref={sourceRef}
        autoplay={autoplay}
        video={videoID + '?dnt=1'}
        background={autoplay}
        loop
        muted
        byline={false}
        title={false}
        onReady={resize}
      />
    </Styled>
  )
}

BackgroundVideo.propTypes = {
  className: PropTypes.any,
  videoID: PropTypes.string,
  sources: PropTypes.array,
  fluid: PropTypes.any,
  autoplay: PropTypes.bool,
}

BackgroundVideo.defaultProps = {
  videoID: '148751763',
  autoplay: true,
}

export default BackgroundVideo
