import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'

const ListItem = styled.li`
  padding: ${({ theme }) => theme.spacing(1.625, 'px')} 0;
  display: block;
  &:not(:last-child) {
    border-bottom: 1px solid transparent;
  }
`

const Styled = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;

  ${({ color, theme }) =>
    color === 'primary' &&
    css`
      ${ListItem} {
        border-color: ${({ theme }) => theme.palette.primary.main};
      }
    `}

  ${({ color, theme }) =>
    color === 'secondary' &&
    css`
      ${ListItem} {
        border-color: ${({ theme }) => theme.palette.secondary.main};
      }
    `}

  ${({ color, theme }) =>
    color === 'white' &&
    css`
      ${ListItem} {
        border-color: white;
      }
    `}
`

const List = props => {
  const { className, children, color } = props

  return (
    <Styled {...{ className, color }}>
      {children &&
        children.map((item, idx) => <ListItem key={idx}>{item}</ListItem>)}
    </Styled>
  )
}

List.propTypes = {
  className: PropTypes.any,
  children: PropTypes.arrayOf(PropTypes.element),
  color: PropTypes.oneOf(['primary', 'secondary', 'white']),
}

List.defaultProps = {}

export default List
