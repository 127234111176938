import React from 'react'
import { graphql } from 'gatsby'
import Layout from 'src/layout'
import Section from 'src/components/Section'
import Hero from 'src/components/Hero'
import GradientOverlay from 'src/components/GradientOverlay'
import FlexibleLogo from 'src/components/FlexibleLogo'
import HeroContent from 'src/components/HeroContent'
import Typography from 'src/components/Typography'
import BackgroundImage from 'src/components/BackgroundImage'
import Container from 'src/components/Container'
import { Box } from '@material-ui/core'
import Facts from 'src/components/Facts'
import Fact from 'src/components/Fact'
import List from 'src/components/List'
import _ from 'lodash'
import Button from 'src/components/Button'
import Progress from 'src/components/Progress'
import Image from 'src/components/Image'
import Grid from 'src/components/Grid'
import BackgroundVideo from 'src/components/BackgroundVideo'
import get from 'lodash/get'
import jobDate from 'src/helper/jobDate'

const Carreer = props => {
  const {
    imagesKarriere: { nodes: imagesKarriere },
    poster: { nodes: poster },
    pages: { nodes: pages },
    jobs: { nodes: jobs },
  } = props.data

  const {
    contactTitle,
    contactText,
    metaTitle,
    metaDescription,
    metaImage,
    contact,
  } = pages[0]

  const metaProps = {
    metaTitle,
    metaDescription,
    metaImage,
  }

  const contactProps = {
    contactTitle,
    contactText,
    contact,
  }

  const facts = [
    {
      title: 'Weiterkommen mit Wissen',
      text:
        'Wir teilen unser Wissen miteinander, begrüßen individuelle Weiterbildung und lernen im Team bei jährlichen eigenen Unternehmensseminaren.',
    },
    {
      title: 'Work-Life-Balance',
      text:
        'Wir leben ein vertrauensvolles Miteinander und finden in Arbeitszeitmodellen zusammen Lösungen für die Vereinbarung von Beruf und Familie.',
    },

    {
      title: 'Arbeiten im Team',
      text:
        'Unsere Kolleginnen und Kollegen sind unser wichtigstes Gut. Mit Neugierde und Teamgeist arbeiten wir gemeinsam an abwechslungsreichen Projekten.',
    },
    {
      title: 'Digitales Arbeitsumfeld',
      text:
        'Bei unserer Arbeit setzen wir auf digitale, agile Prozesse und Methoden wie BIM. Wir entwickeln sie ständig weiter – auch im Austausch miteinander.',
    },
  ]

  return (
    <Layout contact={contactProps} meta={metaProps}>
      {/*
            Hero
      */}
      <Section border={false} flush>
        <Hero transparent>
          <BackgroundVideo
            {...{
              videoID: 'https://player.vimeo.com/video/384528512',
              fluid: poster[0].childImageSharp.fluid,
              autoplay: true,
            }}
          />
          <GradientOverlay color="bottomLeft" multiply />
          <FlexibleLogo color="primary" size={80} />
          <HeroContent>
            <Typography variant="heroLabel" paragraph>
              Karriere
            </Typography>
            <Typography variant="hero" paragraph>
              Raum für Entfaltung mit Empathie und Prozessen.
            </Typography>
          </HeroContent>
        </Hero>
      </Section>
      {/*
            Intro
      */}
      <Section border={false} flush>
        <Container>
          <Box mx="auto" maxWidth={720} mb={60 / 8}>
            <Typography variant="teaser">
              Arbeiten Sie gemeinsam mit uns an abwechslungsreichen Projekten
              und bringen Sie Ihre Neugierde, Kreativität und strukturierte
              Arbeitsweise in unser Team ein.
            </Typography>
          </Box>
          <Box mx="auto" maxWidth={720}>
            <Grid variant="2x">
              {facts.map(({ title, text }, key) => (
                <Box textAlign="center" key={key}>
                  <Typography variant="h2" medium>
                    {title}
                  </Typography>
                  <Box maxWidth={250} mx="auto" mt={1}>
                    <Typography variant="body">{text}</Typography>
                  </Box>
                </Box>
              ))}
            </Grid>
          </Box>
        </Container>
      </Section>
      {/*
            Aktuelle Jobs
      */}
      <Section color="secondary">
        <Container>
          <Typography variant="h1">Aktuelle Jobs</Typography>
          <List color="secondary">
            {jobs.map((entry, key) => (
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                key={key}
              >
                <Typography variant="listTitle">{entry.title}</Typography>
                <Button color="secondary" to={'jobs/' + entry.slug}>
                  {jobDate(entry.created)}
                </Button>
              </Box>
            ))}
          </List>
        </Container>
      </Section>
      {/*
            Wie läuft meine Bewerbung ab
      */}
      <Section color="secondary">
        <Container>
          <Box mb={60 / 8}>
            <Image fluid={imagesKarriere[0].childImageSharp.fluid} />
          </Box>
          <Grid variant="2x">
            <Box>
              <Image fluid={imagesKarriere[1].childImageSharp.fluid} />
            </Box>
            <Box>
              <Image fluid={imagesKarriere[2].childImageSharp.fluid} />
            </Box>
          </Grid>
        </Container>
      </Section>
    </Layout>
  )
}

export default Carreer

export const query = graphql`
  query {
    pages: allPages(filter: { id__normalized: { eq: "karriere" } }) {
      nodes {
        contactTitle
        contactText
        contact {
          ...ContactFragment
        }
        metaTitle
        metaDescription
        metaImage {
          local {
            childImageSharp {
              fixed(width: 3840, quality: 60) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
      }
    }

    poster: allFile(filter: { relativePath: { eq: "poster_karriere.jpg" } }) {
      nodes {
        publicURL
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }

    jobs: allJobs(filter: { id: { ne: "dummy" } }) {
      nodes {
        title
        slug
        created: created_at
      }
    }

    imagesKarriere: allFile(
      filter: {
        relativePath: {
          in: [
            "karriere_einblicke_01.jpg"
            "karriere_einblicke_02.jpg"
            "karriere_einblicke_03.jpg"
          ]
        }
      }
      sort: { fields: relativePath }
    ) {
      nodes {
        publicURL
        childImageSharp {
          fluid(maxWidth: 1080) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  }
`
