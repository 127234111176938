import { format } from 'date-fns'

const jobDate = ts => {
  const date = new Date(ts)
  if (date < new Date()) {
    return 'Ab sofort'
  } else {
    return `${format(date, 'dd.MM.yyyy')}`
  }
}

export default jobDate
