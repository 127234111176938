import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import padStart from 'lodash/padStart'

const Styled = styled.div`
  font-family: ${({ theme }) => theme.typo.primary};
  font-weight: ${({ theme }) => theme.typo.weight.regular};
  justify-content: center;
  text-align: center;
  display: flex;
`

const StyledContainer = styled.div`
  width: 200px;

  &:not(:first-child) {
    margin-left: 50px;
  }
`

const StyledNumber = styled.div`
  align-items: center;
  font-size: 50px;
  display: flex;

  &:before,
  &:after {
    content: '';
    display: block;
    border: 1px solid;
    flex: 1;
  }

  &:before {
    margin-right: 10px;
  }

  &:after {
    margin-left: 10px;
  }

  ${({ last }) => !last && `
    &:after {
      padding-right: 50px;
      margin-right: -50px;
    }
  `}

  ${({ first }) => first && `
    &:before {
      border: none;
    }
  `}

  ${({ last }) => last && `
    &:after {
      border: none;
    }
  `}
`

const Progress = props => {
  const { children, ...passProps } = props

  return (
    <Styled {...passProps}>
      {children.map((child, i) => (
        <StyledContainer key={i}>
          <StyledNumber first={i === 0} last={(i + 1) === children.length}>
            {padStart(i + 1, 2, 0)}
          </StyledNumber>
          {child}
        </StyledContainer>
      ))}
    </Styled>
  )
}

Progress.propTypes = {
  children: PropTypes.any,
}

Progress.defaultProps = {
}

export default Progress
